import React from "react";
import Layout from "../components/layout";
import CTA from "../components/cta";
import Seo from "../components/seo"

import background from "../images/backrounds/city-background.jpg";
import {StaticImage} from "gatsby-plugin-image";
import Quote from "../components/quote";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Integrations"
        description="Since 2003, 729 has been building integrations to get tools you use everyday speaking to eaching other - making your job faster and more efficient."
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${background})`,
          }}
        >
          <div className="hero-title bg-primary-transparent">
            <h1 className="text-white">we can help you integrate services to connect and optimize your business
              processes</h1>
            <p className="text-white">
              Every business is a technology company these days. We all rely on software to transact with customers,
              products, and services. We use it in every corner of our business from user engagement to the back office.
              Since 2003, 729 has been building connections between tools so people can get their jobs done more quickly
              and efficiently.
            </p>
            <CTA
              href="/contact-us/"
              text="CONTACT US"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="d-flex row">
            <div className="col-lg-6 order-lg-1">
              <StaticImage src="../images/backrounds/optimize-process.png"
                           alt="Optimize Process"/>
            </div>
            <div className="col-lg-6 order-lg-0 mt-4 mt-lg-0">
              <h2>how 729 can help you optimize your business processes:</h2>
              <ul className="text-primary">
                <li><span className="text-dark">We’ll Audit your current toolset</span></li>
                <li><span className="text-dark">Assess your current workflows and identify pain points</span></li>
                <li><span className="text-dark">Evaluate and recommend new software or tools that have the right features and price point for your
                  business
                </span></li>
                <li><span className="text-dark">Create seamless integrations between your tools</span></li>
                <li><span
                  className="text-dark">Set up new databases or cloud hosting to support your new integrations</span>
                </li>
              </ul>
              <CTA text="talk to us about integrations" className="btn btn-primary mt-5" href="/contact-us/"/>
            </div>

          </div>
        </div>
      </section>
      <section
        className="bg-primary"
      >
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-4">
              <h2 style={{borderBottom: '3px solid #76bc21'}} className="text-white">Improve Business Workflows</h2>
            </div>
            <div className="col-lg-8">
              <p className="text-white">Everything can be reduced to workflows. </p>
              <p className="text-white">How hard is it to get answers, resources, numbers, invoices, etc?
                <br></br>How quickly can someone buy from you?
                <br></br>How long does it take to bill them?
              </p>
              <p className="text-white">Your Profit &amp; Loss (P&amp;L), Cash flow and even employee satisfaction are
                all tied to successful
                workflows. Let us reduce friction, move data easier and help you grow faster.</p>
            </div>
          </div>
          <div className="row mt-6">
            <div className="col-lg-4">
              <h2 style={{borderBottom: '3px solid #76bc21'}} className="text-white">Ditch the spreadsheets and welcome
                to {new Date().getFullYear()}!</h2>
            </div>
            <div className="col-lg-8">
              <p className="text-white">More than 30% of companies still use excel to manage core reports, financial
                processes, or customer
                information. If you are one of them we can assure you there is a better way! </p>
              <p className="text-white">What we have learned about data being a 17-year-old Systems Integrator is the
                value of storing and
                moving data efficiently. If you have processes resting on excel we can help you optimize the way you
                work and recommend the best new tools for your business.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row d-flex">
            <div className="col-lg-6 order-lg-1">
              <StaticImage src="../images/backrounds/shaking-hands.jpg"
                           alt="Shaking Hands"/>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0 order-lg-0">
              <h2 className="mb-1">let’s talk about how we can optimize processes for your business</h2>
              <p>We’re confident you’ll choose 729 Solutions because we offer a few things that are hard to find
                anywhere else.</p>
              <CTA text="Schedule a Free Consultation" className="btn btn-primary mt-4" href="/contact-us/"/>
            </div>

          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <Quote
                name="Deeanne Akerson"
                company="Kindred Bravely"
                quote="We are thrilled, and you bet I will be recommending 729 Solutions to anyone else needing help with their Zendesk integrations!"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
